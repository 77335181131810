import clsx from "clsx";
import { graphql } from "gatsby";
import moment from "moment";
import { RichText } from "prismic-reactjs";
import React from "react";

import CategoryIcon from "../components/categoryIcon";
import Layout from "../components/layout";
import NewsletterContainer from "../components/newsletter";
import PrismicHref from "../components/prismicHref";
import SEO from "../components/seo";
import classes from "./article.module.css";

export const query = graphql`
	query PostQuery($uid: String) {
		prismic {
			allPosts(uid: $uid) {
				edges {
					node {
						_linkType
						author {
							_linkType
							... on PRISMIC_Employee {
								employee_name
								employee_title
								employee_photo
								_meta {
									uid
									type
								}
							}
						}
						body {
							... on PRISMIC_PostBodyText {
								type
								label
								primary {
									text
								}
							}
							... on PRISMIC_PostBodyImage {
								type
								label
								primary {
									image
								}
							}
						}
						date
						title
						post_category
					}
				}
			}
		}
	}
`;

const getClassForCategory = postCategory => {
	if (postCategory === "Update") {
		return "headerContainerUpdate";
	} else if (postCategory === "Newsletter") {
		return "headerContainerNewsletter";
	}
	return "headerContainerBlog";
};

const Article = props => {
	const doc = props.data.prismic.allPosts.edges.slice(0, 1).pop();
	if (!doc) {
		return <div>404</div>;
	}
	const article = doc.node;

	return (
		<Layout>
			<SEO title="Articles" />
			<section className={classes.container}>
				<article>
					<header
						className={clsx(
							classes.headerContainer,
							classes[getClassForCategory(article.post_category)]
						)}
					>
						<div>
							<div className={classes.category}>
								<CategoryIcon
									postCategory={article.post_category}
									iconContainerClass={classes.headerIcon}
								/>
							</div>
							<div className={classes.header}>
								{RichText.asText(article.title)}
							</div>
							<div className={classes.subtext}>
								Written by{" "}
								{article?.author?.employee_name &&
								article?.author?.employee_name[0].text !==
									"Bridge Law Group, Ltd." ? (
									<PrismicHref link={article.author}>
										{RichText.asText(
											article.author.employee_name
										)}
									</PrismicHref>
								) : (
									"Bridge Law Group"
								)}{" "}
								on{" "}
								{moment(article.date).format("MMMM Do, YYYY")}
							</div>
						</div>
					</header>
					<div className={classes.bodyContainer}>
						{article.body &&
							article.body.map((item, i) => {
								if (item.type === "text") {
									return (
										<div key={i}>
											{RichText.render(item.primary.text)}
										</div>
									);
								}
								return null;
							})}
						{article.body && RichText.asText(article.body)}
					</div>
				</article>
				{article?.author && (
					<footer className={classes.footer}>
						<NewsletterContainer />
					</footer>
				)}
			</section>
		</Layout>
	);
};

export default Article;
