import clsx from "clsx";
import React, { ReactElement } from "react";

import BlogIcon from "../../assets/post-category-blog.svg";
import NewsletterIcon from "../../assets/post-category-newsletter.svg";
import UpdateIcon from "../../assets/post-category-update.svg";
import classes from "./categoryIcon.module.css";

interface Props {
	iconContainerClass?: string;
	postCategory: "Newsletter" | "Blog Post" | "Update";
}

const CategoryIcon = (props: Props): ReactElement => {
	if (props.postCategory === "Blog Post") {
		return (
			<div
				className={clsx(
					classes.iconContainer,
					classes.blog,
					props.iconContainerClass
				)}
			>
				<BlogIcon className={classes.icon} />
			</div>
		);
	} else if (props.postCategory === "Update") {
		return (
			<div
				className={clsx(
					classes.iconContainer,
					classes.update,
					props.iconContainerClass
				)}
			>
				<UpdateIcon className={classes.icon} />
			</div>
		);
	}
	return (
		<div
			className={clsx(
				classes.iconContainer,
				classes.newsletter,
				props.iconContainerClass
			)}
		>
			<NewsletterIcon className={classes.icon} />
		</div>
	);
};

export default CategoryIcon;
